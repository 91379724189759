import { useCallback, useMemo, useState } from "react";
import { Badge, Button, Container } from "react-bootstrap";
import { Trans } from "react-i18next";
import { parseProtPropertiesStr } from "../../utils/protPropertiesStr";
import { parseRiskFactorsStr } from "../../utils/riskFactors";
import NormEdit from "./NormEdit";
import NormDetails from "./NormDetails";

function NormTableRow({ norm, allRiskFactors, allProtProperties, onSave, onDelete }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const isClient = useMemo(() => norm.client_id, [norm]);
    const parsedRiskFactors = useMemo(() => parseRiskFactorsStr(norm.risk_factors_str), [norm]);
    const parsedProtProperties = useMemo(() => parseProtPropertiesStr(norm.prot_properties_str), [norm]);

    const getRiskFactorName = useCallback((codeName) => {
        if (!allRiskFactors) {
            return null;
        }

        return allRiskFactors.filter((rf) => rf.codeName === codeName)[0]?.name;
    }, [allRiskFactors]);

    const getPropertyName = useCallback((codeName) => {
        if (!allProtProperties) {
            return null;
        }

        return allProtProperties.filter((prop) => prop.codeName === codeName)[0]?.name;
    }, [allProtProperties]);

    return [
        <tr key={norm.id} className={!isClient ? "table-secondary" : ""}>
            <td>{norm.name}</td>
            <td className="d-none d-md-table-cell">
                {allRiskFactors ? (
                    parsedRiskFactors.map((rfCodeName, index) => (
                        <div>
                            <Badge color="secondary" className="me-1">{rfCodeName}</Badge> {getRiskFactorName(rfCodeName) || <></>}
                        </div>
                    ))
                ) : (
                    <span>{norm.risk_factors_str}</span>
                )}
            </td>
            <td className="d-none d-md-table-cell">
                {allProtProperties ? (
                    parsedProtProperties.map((propVariants) => (
                        <div>
                            {propVariants.map((propCodeName, index) => (
                                <div>
                                    {index > 0 && <span className="ms-2"><Trans>normProtPropertyOr</Trans></span>} <Badge color="secondary" className="me-1">{propCodeName}</Badge> {getPropertyName(propCodeName) || <></>}
                                </div>
                            ))}
                        </div>
                    ))
                ) : (
                    <span>{norm.prot_properties_str}</span>
                )}
            </td>
            <td>
                {isClient && (
                    <Button
                        variant="link"
                        size="sm"
                        onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
                    >{isExpanded ? <Trans>normTableHide</Trans> : <Trans>normTableShow</Trans>}</Button>
                )}
            </td>
        </tr>,
        isExpanded && (
            <tr key={`${norm.id}-details`}>
                <td colSpan={3}>
                    <Container>
                        {isEdit ? (
                            <NormEdit
                                norm={norm}
                                onSave={onSave}
                                onDelete={onDelete}
                                onCancel={() => setIsEdit(false)}
                            />
                        ) : (
                            <NormDetails
                                norm={norm}
                                onEdit={() => setIsEdit(true)}
                                onDelete={onDelete} />
                        )}
                    </Container>
                </td>
            </tr>
        )
    ];
}

export default NormTableRow;
