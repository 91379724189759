import { t } from "i18next";
import { Helmet } from "react-helmet-async";
import Header from "../components/global/Header";
import { Container } from "react-bootstrap";
import Footer from "../components/global/Footer";
import { useEffect, useMemo, useState } from "react";
import { showNotification } from "../utils/notification";
import Loader from "../components/global/Loader";


function PrivacyPolicy() {
    const language = useMemo(() => {
        const userLang = navigator.language || navigator.userLanguage;
        return userLang.startsWith('ru') ? 'ru' : 'en';
    }, []);

    const fileUrl = useMemo(() => {
        if (language === 'ru') {
            return '/documents/Политика конфиденциальности.txt';
        } else {
            return '/documents/Privacy Policy.txt';
        }
    }, [language]);

    const [text, setText] = useState("");

    useEffect(() => {
        if (fileUrl) {
            fetch(fileUrl)
                .then(response => response.text())
                .then(data => {
                    setText(data);
                })
                .catch(error => {
                    console.error(error);
                    showNotification(t("privacyPolicyFetchError") + ": " + error.message, 'danger');
                });
        }
    }, [fileUrl, language]);

    return (
        <>
            <Helmet>
                <title>{t("privacyPolicyPageTitle")} - FeetApp</title>
            </Helmet>

            <Header />

            <Container className="mt-3">
                {text ? (
                    <div style={{ whiteSpace: "pre-wrap" }}>{text}</div>
                ) : (
                    <Loader />
                )}
            </Container>

            <Footer />
        </>
    );
}

export default PrivacyPolicy;
