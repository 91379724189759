import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { Alert, Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import useDebounce from "../../utils/useDebounce";
import { Trans } from "react-i18next";
import { showNotification } from "../../utils/notification";
import useProfessionApi from "../../app/useProfessionApi";

const NormSelect = ({ normList, active, onEditing, selectedNorm, setSelectedNorm, size }) => {
    const professionApi = useProfessionApi();

    const [filterStr, setFilterStr, debouncedFilterStr] = useDebounce("", 500);
    const [curNormList, setCurNormList] = useState(normList);

    const filteredNorms = useMemo(() => {
        if (!curNormList) {
            return [];
        }

        if (!debouncedFilterStr) {
            return curNormList;
        }

        if (normList) {
            // Client-side filter
            return curNormList.filter((norm) => {
                return norm.toLowerCase().includes(debouncedFilterStr.toLowerCase());
            });
        } else {
            // Server-side filter
            return curNormList;
        }
    }, [curNormList, debouncedFilterStr, normList]);

    useEffect(() => {
        async function updateList() {
            try {
                const list = await professionApi.getNormList(true);
                setCurNormList(list);
            } catch (e) {
                console.error(e);
                showNotification(t("errorLoadingNorms"), "danger");
            }
        }

        if (!normList) {
            updateList();
        }
    }, [debouncedFilterStr, normList, professionApi]);

    return (
        <div>
            {/* Filter input */}
            {onEditing && (
                <InputGroup className="my-3 text-sm">
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Form.Control
                        type="text"
                        placeholder={t("normSearch")}
                        value={filterStr}
                        onChange={(e) => setFilterStr(e.target.value)}
                        onFocus={() => onEditing(true)}
                    />
                </InputGroup>
            )}

            {/* List with scroll */}
            {active && (
                <div className="d-flex flex-column my-3" style={{ height: "400px", overflowY: "auto" }}>
                    <Container>
                        <Row>
                            {filteredNorms.length > 0 ? filteredNorms.map((norm) => [
                                <Button
                                    className="my-1"
                                    key={norm}
                                    variant={selectedNorm === norm ? "primary" : "outline-primary"}
                                    style={{ border: "none", textAlign: "left" }}
                                    // className="w-100"
                                    onClick={() => {
                                        setSelectedNorm(norm);
                                    }}
                                >
                                    {norm}
                                </Button>
                            ]).flat() : (
                                <div className="text-center text-secondary"><Trans>noResults</Trans></div>
                            )}
                        </Row>
                    </Container>
                </div>
            )}

            {/* Selected */}
            {!active && selectedNorm && (
                <Alert variant="primary" className="my-3">
                    {size !== "sm" ? <Alert.Heading>{selectedNorm}</Alert.Heading> : <div>{selectedNorm}</div>}
                </Alert>
            )}
        </div>
    );
};

export default NormSelect;
