import { useCallback, useEffect, useState } from "react";
import useEmployeeApi from "../../app/useEmployeeApi";
import { apiGetMeasurements } from "../../app/measurementApi";
import { showNotification } from "../../utils/notification";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import MeasurementCards from "./MeasurementCards";

function EmployeesMeasurements({ employees }) {
    const employeeApi = useEmployeeApi();

    const [measurementEmployeeIds, setMeasurementEmployeeIds] = useState(null);
    const [measurements, setMeasurements] = useState(null);

    const updateMeasurements = useCallback(async () => {
        if (!employeeApi.ready()) {
            return;
        }

        try {
            // Fetch employees measurements (pairs of [measurementId, employeeId])
            const meIds = await employeeApi.getEmployeesMeasurements();

            // Extract array of measurement ids
            const mIds = meIds.map(meId => meId.measurement_id);

            // Fetch measurements
            let ms = await apiGetMeasurements(mIds);

            // Append employeeId to each measurement
            ms = ms.map(m => ({ ...m, employeeId: meIds.find(meId => meId.measurement_id === m.measurementId).employee_id }));

            setMeasurementEmployeeIds(meIds);
            setMeasurements(ms);
        } catch (err) {
            console.log(err);
            showNotification(t("employeesMeasurementsFetchFailed") + ": " + err, 'danger');
        }
    }, [employeeApi]);

    const deleteMeasurement = useCallback(async (measurementId) => {
        if (!employeeApi.ready()) {
            showNotification(t("employeesMeasurementsNotReady"), 'danger');
            return;
        }

        // Find measurement by id
        const measurement = measurements.find(measurement => measurement.measurementId === measurementId);
        if (!measurement) {
            showNotification(t("employeesMeasurementsDeleteFailed"), 'danger');
            return;
        }

        try {
            await employeeApi.deleteEmployeeMeasurement(measurement.employeeId, measurementId);
            await updateMeasurements();
        } catch (err) {
            console.log(err);
            showNotification(t("employeesMeasurementsDeleteFailed") + ": " + err, 'danger');
        }
    }, [employeeApi, measurements, updateMeasurements]);

    const findEmployee = useCallback((measurementId) => {
        if (!measurementEmployeeIds) {
            return null;
        }

        const employeeId = measurementEmployeeIds.find(meId => meId.measurement_id === measurementId)?.employee_id;
        if (!employeeId) {
            return null;
        }

        return employees?.find(employee => employee.id === employeeId);
    }, [employees, measurementEmployeeIds]);

    useEffect(() => {
        if (employeeApi.ready()) {
            updateMeasurements();
        }

        // Update timer
        const timer = setInterval(() => {
            updateMeasurements();
        }, 5000);

        return () => clearInterval(timer);
    }, [employeeApi, updateMeasurements]);

    return (
        <>
            <div className="my-2 fst-italic">
                <Trans>employeesMeasurementsGoToList</Trans> <a href="/profile"><FontAwesomeIcon icon={faArrowRight} /><Trans>emploeeList</Trans></a>
            </div>

            <MeasurementCards measurements={measurements} findEmployee={findEmployee} handleRemove={deleteMeasurement} />
        </>
    );
}

export default EmployeesMeasurements;
