function parseRiskFactorsStr(riskFactorsStr) {
    return riskFactorsStr
        .split(",")
        .map((item) => item.trim())
        .filter((item) => item.length > 0);
}

function buildRiskFactorsStr(riskFactors) {
    return riskFactors
        .filter((item) => item.length > 0)
        .join(",");
}

export { parseRiskFactorsStr, buildRiskFactorsStr };
