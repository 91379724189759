import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import useDebounce from "../../utils/useDebounce";
import { Trans } from "react-i18next";
import { showNotification } from "../../utils/notification";
import useProfessionApi from "../../app/useProfessionApi";
import Loader from "../global/Loader";
import FilterStringInput from "../global/FilterStringInput";

const ProfessionSelect = ({ professionList, active, onEditing, selectedProfession, setSelectedProfession, size }) => {
    const professionApi = useProfessionApi();

    const [filterStr, setFilterStr, debouncedFilterStr] = useDebounce("", 500);
    const [curProfessionList, setCurProfessionList] = useState(professionList);

    const [loading, setLoading] = useState(false);

    const filteredProfessions = useMemo(() => {
        if (!curProfessionList) {
            return [];
        }

        if (!debouncedFilterStr) {
            return curProfessionList;
        }

        if (professionList) {
            // Client-side filter
            return curProfessionList.filter((profession) => {
                return profession.name.toLowerCase().includes(debouncedFilterStr.toLowerCase());
            });
        } else {
            // Server-side filter
            return curProfessionList;
        }
    }, [curProfessionList, debouncedFilterStr, professionList]);

    useEffect(() => {
        async function updateList() {
            setLoading(true);

            try {
                const list = await professionApi.getProfessionList(debouncedFilterStr);
                setCurProfessionList(list);
            } catch (e) {
                console.error(e);
                showNotification(t("errorLoadingProfessions"), "danger");
            }

            setLoading(false);
        }

        if (!professionList && debouncedFilterStr.length >= 3) {
            updateList();
        }
    }, [debouncedFilterStr, professionApi, professionList]);

    return (
        <div>
            {/* Filter input */}
            <div className="my-3">
                <FilterStringInput filterStr={filterStr} setFilterStr={setFilterStr} onEditing={onEditing} />
            </div>

            {/* Professions list with scroll */}
            {active && (
                <div className="d-flex flex-column my-3" style={{ height: "400px", overflowY: "auto" }}>
                    <Container>
                        {!loading ? (
                            <Row>
                                {debouncedFilterStr.length >= 3 ? (
                                    filteredProfessions.length > 0 ? filteredProfessions.map((profession) => [
                                        <Col xs={12} sm={6} key={profession.name + "." + profession.industry}>
                                            <Button
                                                className="my-1"                                            
                                                variant={selectedProfession?.name === profession.name ? "primary" : "outline-primary"}
                                                style={{ border: "none", textAlign: "left" }}
                                                // className="w-100"
                                                onClick={() => {
                                                    setSelectedProfession(profession);
                                                }}
                                            >
                                                {profession.name}
                                            </Button>
                                        </Col>,
                                        <Col xs={12} sm={6}>
                                            <div>{profession.industry}</div>
                                        </Col>,
                                    ]).flat() : (
                                        <div className="text-center text-secondary"><Trans>noResults</Trans></div>
                                    )) : (
                                    <div className="text-center text-secondary"><FontAwesomeIcon icon={faSearch} /> <Trans>professionsUseSearch</Trans></div>
                                )}
                            </Row>
                        ) : (
                            <Loader />
                        )}
                    </Container>
                </div>
            )}

            {/* Selected profession */}
            {!active && selectedProfession && (
                <Alert variant="primary" className="my-3">
                    {size !== "sm" ? <Alert.Heading>{selectedProfession.name}</Alert.Heading> : <div>{selectedProfession.name}</div>}
                    {selectedProfession.industry && <p>{selectedProfession.industry}</p>}
                </Alert>
            )}
        </div>
    );
};

export default ProfessionSelect;
