class ClientProfessionApi {
    constructor(sessionId) {
        this.sessionId = sessionId;
    }

    ready() {
        return !!this.sessionId;
    }

    async addRiskFactor(riskFactor) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/add_risk_factor';

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(riskFactor)
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        return await response.json();
    }

    async getRiskFactor({ id, codeName }) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/get_risk_factor';

        const params = new URLSearchParams();
        if (id) {
            params.append('id', id);
        }
        if (codeName) {
            params.append('code_name', codeName);
        }

        const headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint + "?" + params, { headers: headers });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        return await response.json();
    }

    async getRiskFactors(client_only=false, query_str='') {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/get_risk_factors';

        const params = new URLSearchParams();
        if (client_only) {
            params.append('client_only', client_only);
        }
        if (query_str) {
            params.append('query_str', query_str);
        }

        const headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint + "?" + params, { headers: headers });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        return await response.json();
    }

    async updateRiskFactor(riskFactor) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/update_risk_factor';

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(riskFactor)
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        return await response.json();
    }

    async deleteRiskFactor({ id, codeName }) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/delete_risk_factor';

        const params = new URLSearchParams();
        if (id) {
            params.append('id', id);
        }
        if (codeName) {
            params.append('code_name', codeName);
        }

        const headers = {
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint + "?" + params, { headers: headers });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }
    }

    async addProfession(profession) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/add_profession';

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(profession)
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        return await response.json();
    }

    async getProfession({ id }) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/get_profession';

        const params = new URLSearchParams();
        if (id) {
            params.append('id', id);
        }

        const headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint + "?" + params, { headers: headers });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        return await response.json();
    }

    async getProfessions(client_only=false, query_str='') {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/get_professions';

        const params = new URLSearchParams();
        if (client_only) {
            params.append('client_only', client_only);
        }
        if (query_str) {
            params.append('query_str', query_str);
        }

        const headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint + "?" + params, { headers: headers });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        return await response.json();
    }

    async updateProfession(profession) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/update_profession';

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(profession)
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        return await response.json();
    }

    async deleteProfession({ id }) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/delete_profession';

        const params = new URLSearchParams();
        if (id) {
            params.append('id', id);
        }

        const headers = {
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint + "?" + params, { headers: headers });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }
    }

    async addNorm(norm) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/add_norm';

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(norm)
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        return await response.json();
    }

    async getNorm({ id }) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/get_norm';

        const params = new URLSearchParams();
        if (id) {
            params.append('id', id);
        }

        const headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint + "?" + params, { headers: headers });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        return await response.json();
    }

    async getNorms(client_only=false, query_str='') {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/get_norms';

        const params = new URLSearchParams();
        if (client_only) {
            params.append('client_only', client_only);
        }
        if (query_str) {
            params.append('query_str', query_str);
        }

        const headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint + "?" + params, { headers: headers });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        return await response.json();
    }

    async updateNorm(norm) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/update_norm';

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(norm)
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        return await response.json();
    }

    async deleteNorm({ id }) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/delete_norm';

        const params = new URLSearchParams();
        if (id) {
            params.append('id', id);
        }

        const headers = {
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint + "?" + params, { headers: headers });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }
    }
}

export default ClientProfessionApi;
