import { faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Collapse, Container, Form, InputGroup, Row } from "react-bootstrap";
import { Trans } from "react-i18next";
import { showNotification } from "../../utils/notification";
import Loader from "../global/Loader";
import useClientProfessionApi from "../../app/useClientProfessionApi";
import NormSelect from "./NormSelect";

function ProfessionEdit({ profession, onSave, onDelete, onCancel }) {
    const clientProfessionApi = useClientProfessionApi();

    const [name, setName] = useState(profession?.name || "");
    const [industry, setIndustry] = useState(profession?.industry || "");
    const [norm, setNorm] = useState(profession?.norm || "");

    const [allNorms, setAllNorms] = useState(null);

    const [showNormSelect, setShowNormSelect] = useState(false);
    const normSelectTarget = useRef(null);

    const reset = useCallback(() => {
        setName(profession.name);
        setIndustry(profession.industry);
        setNorm(profession.norm);
    }, [profession.industry, profession.name, profession.norm]);

    const clear = useCallback(() => {
        setName("");
        setIndustry("");
        setNorm("");
    }, []);

    const onCancelClick = useCallback(() => {
        if (profession.id) {
            reset();
        } else {
            clear();
        }

        onCancel();
    }, [profession, onCancel, reset, clear]);

    const onSaveClick = useCallback(() => {
        onSave({
            id: profession.id,
            name: name,
            industry: industry,
            norm: norm
        });

        onCancel();
    }, [onSave, profession.id, name, industry, norm, onCancel]);

    const handleNormSelected = useCallback((norm) => {
        setNorm(norm);
        setShowNormSelect(false);
    }, []);

    useEffect(() => {
        async function fetchData() {
            try {
                const norms = await clientProfessionApi.getNorms(false);
                const normNames = norms.map(norm => norm.name);
                setAllNorms(normNames);
            } catch (e) {
                console.error(e);
                showNotification(t("professionErrorLoadingNorms"), "danger");
            }
        }

        const delayedFetch = setTimeout(fetchData, 200);

        return () => clearTimeout(delayedFetch);
    }, [clientProfessionApi]);

    return (
        <Container>
            <Row>
                <Col xs={12} lg={6} className="mx-auto">
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label><Trans>professionName</Trans></Form.Label>
                            <Form.Control
                                type="text"
                                value={name}
                                onChange={e => setName(e.target.value)}
                                placeholder={t("normNamePlaceholder")}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label><Trans>professionIndustry</Trans></Form.Label>
                            <Form.Control
                                type="text"
                                value={industry}
                                onChange={e => setIndustry(e.target.value)}
                                placeholder={t("normIndustryPlaceholder")}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label><Trans>professionNorm</Trans></Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    value={norm}
                                    readOnly
                                    placeholder={t("normProtPropertiesStrPlaceholder")}
                                />

                                {allNorms === null ? (
                                    <Loader compact={true} />
                                ) : (
                                    <>
                                        <Button
                                            variant="outline-secondary"
                                            ref={normSelectTarget}
                                            onClick={() => setShowNormSelect((show) => !show)}
                                        >
                                            <Trans>normSelect</Trans>
                                        </Button>
                                    </>
                                )}
                            </InputGroup>

                            {allNorms !== null && (
                                <Collapse in={showNormSelect}>
                                    <div id="collapse-norm-select">
                                        <NormSelect
                                            normList={allNorms}
                                            active={true}
                                            selectedNorm={norm}
                                            setSelectedNorm={handleNormSelected}
                                            onEditing={(active) => setShowNormSelect(active)}
                                        />
                                    </div>
                                </Collapse>
                            )}
                        </Form.Group>

                        <div className="d-flex justify-content-center">
                            {norm.id !== undefined && (
                                <Button
                                    variant="danger"
                                    className="me-auto"
                                    onClick={() => onDelete(norm.id)}
                                >
                                    <FontAwesomeIcon icon={faTrash} /> <Trans>Delete</Trans>
                                </Button>
                            )}

                            <Button
                                variant="secondary"
                                className="ms-auto me-2"
                                onClick={onCancelClick}
                            >
                                <Trans>Cancel</Trans>
                            </Button>

                            <Button
                                variant="primary"
                                className="me-auto"
                                onClick={onSaveClick}
                            >
                                <FontAwesomeIcon icon={faSave} /> <Trans>Save</Trans>
                            </Button>
                        </div>

                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default ProfessionEdit;
