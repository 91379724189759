import { Trans } from "react-i18next";
import "../../styles/Loader.scss";

const Loader = ({ compact, text, spinnerImage }) => {
    function getText() {
        if (text) {
            return text + "...";
        } else {
            return <Trans>Loading...</Trans>;
        }
    }

    return (
        <div>
            {!compact && (
                <p className="text-center">{getText()}</p>
            )}
            <div className="d-flex justify-content-center">
                {spinnerImage ? (
                    <div className="custom-spinner">
                        <img src={spinnerImage} alt="Loading..." className="spinner-image" />
                    </div>
                ) : (
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">{getText()}</span>
                    </div>
                )}
            </div>
        </div>
    )
};

export default Loader;
