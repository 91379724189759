import { Trans } from "react-i18next";
import { useEffect, useState } from "react";
import useProfessionApi from "../../app/useProfessionApi";
import { showNotification } from "../../utils/notification";
import { t } from "i18next";
import ProfessionTableRow from "./ProfessionTableRow";

function ProfessionTable({ professions, showClientOnly, onSave, onDelete }) {
    const professionApi = useProfessionApi();
    const [allNorms, setAllNorms] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const n = await professionApi.getNormList();
                setAllNorms(n);
            } catch (e) {
                console.error(e);
                showNotification(t("errorLoadingNorms"), "danger");
            }
        }

        const delayedFetch = setTimeout(() => {
            fetchData();
        }, 200);

        return () => clearTimeout(delayedFetch);
    }, [professionApi]);

    return (
        <table className="table table-hover mt-3">
            <thead>
                <tr>
                    <th scope="col"><Trans>professionTableName</Trans></th>
                    <th scope="col" className="d-none d-md-table-cell"><Trans>professionTableIndustry</Trans></th>
                    <th scope="col"><Trans>professionTableNorm</Trans></th>
                    <th scope="col"><Trans>riskFactorTableAction</Trans></th>
                </tr>
            </thead>

            <tbody>
                {professions.length > 0 ? professions.map((profession) => (
                    (!showClientOnly || profession.client_id) && (
                        <ProfessionTableRow profession={profession} allNorms={allNorms} key={profession.id} onSave={onSave} onDelete={onDelete} />
                    )
                )) : (
                    <tr>
                        <td colSpan={4}><Trans>professionTableEmpty</Trans></td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

export default ProfessionTable;