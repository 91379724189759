import { Trans } from "react-i18next";
import { useEffect, useState } from "react";
import useProfessionApi from "../../app/useProfessionApi";
import { showNotification } from "../../utils/notification";
import { t } from "i18next";
import NormTableRow from "./NormTableRow";

function NormTable({ norms, showClientOnly, onSave, onDelete }) {
    const professionApi = useProfessionApi();
    const [allRiskFactors, setAllRiskFactors] = useState(null);
    const [allProtProperties, setAllProtProperties] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const rf = await professionApi.getRiskFactorList(true);
                setAllRiskFactors(rf);

                const props = await professionApi.getProtectivePropertyList(true);
                setAllProtProperties(props);                
            } catch (e) {
                console.error(e);
                showNotification(t("errorLoadingProtectiveProperties"), "danger");
            }
        }

        const delayedFetch = setTimeout(() => {
            fetchData();
        }, 200);

        return () => clearTimeout(delayedFetch);
    }, [professionApi]);

    return (
        <table className="table table-hover mt-3">
            <thead>
                <tr>
                    <th scope="col"><Trans>normTableName</Trans></th>
                    <th scope="col" className="d-none d-md-table-cell"><Trans>normTableRiskFactors</Trans></th>
                    <th scope="col" className="d-none d-md-table-cell"><Trans>normTableProtProperties</Trans></th>
                    <th scope="col"><Trans>normTableAction</Trans></th>
                </tr>
            </thead>

            <tbody>
                {norms.length > 0 ? norms.map((norm) => (
                    (!showClientOnly || norm.client_id) && (
                        <NormTableRow norm={norm} allRiskFactors={allRiskFactors} allProtProperties={allProtProperties} key={norm.id} onSave={onSave} onDelete={onDelete} />
                    )
                )) : (
                    <tr>
                        <td colSpan={4}><Trans>normTableEmpty</Trans></td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

export default NormTable;