import { useCallback, useEffect, useState } from "react";
import useLocalStorageState from "use-local-storage-state"
import { apiGetMeasurements } from "../../app/measurementApi";
import { showNotification } from "../../utils/notification";
import { t } from "i18next";
import Loader from "../global/Loader";
import { Trans } from "react-i18next";
import AddMeasurementForm from "./AddMeasurementForm";
import MeasurementCards from "./MeasurementCards";

const LocalMeasurements = ({ measurementIds }) => {
    const [newMeasurementsAdded, setNewMeasurementsAdded] = useState(false);

    const [localMeasurementIds, setLocalMeasurementIds] = useLocalStorageState('localMeasurementIds', { defaultValue: [] });
    const [isLoading, setIsLoading] = useState(false);
    const [measurements, setMeasurements] = useState([]);

    const addMeasurement = useCallback((measurementId) => {
        setLocalMeasurementIds([...localMeasurementIds, measurementId]);
    }, [localMeasurementIds, setLocalMeasurementIds]);

    const removeMeasurement = useCallback((measurementId) => {
        setLocalMeasurementIds(localMeasurementIds.filter(id => id !== measurementId));
    }, [localMeasurementIds, setLocalMeasurementIds]);

    useEffect(() => {
        let localIds = localMeasurementIds;

        if (measurementIds) {
            // Add all measurements from the list ID1,ID2,... to local measurements
            const newMeasurements = measurementIds.split(',');
            const updatedMeasurements = [...localIds, ...newMeasurements];

            // Remove duplicates
            const uniqueMeasurements = [...new Set(updatedMeasurements)];

            setLocalMeasurementIds(uniqueMeasurements);
            localIds = uniqueMeasurements;

            setNewMeasurementsAdded(true);
        }

        const fetchMeasurements = () => {
            setIsLoading(true);
            apiGetMeasurements(localIds)
                .then(data => {
                    setIsLoading(false);
                    setMeasurements(data);
                })
                .catch(err => {
                    console.log(err);
                    showNotification(t("Error loading personal measurements") + ": " + err, 'danger');
                    setIsLoading(false);
                });
        };

        if (localIds) {
            fetchMeasurements();
        }
    }, [localMeasurementIds, measurementIds, setLocalMeasurementIds]);

    // Early returns
    if (!localMeasurementIds) {
        return null;
    }

    if (isLoading) {
        return (
            <Loader />
        );
    }

    // Main render
    return (
        <>
            {newMeasurementsAdded && (
                <div className="alert alert-success">
                    <p><Trans>Measurements added</Trans></p>
                </div>
            )}

            <MeasurementCards measurements={measurements} handleRemove={removeMeasurement} />

            <div className="mt-3">
                <AddMeasurementForm handleAdded={addMeasurement} />
            </div>
        </>
    );
};

export default LocalMeasurements;
