import { faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Trans } from "react-i18next";
import useEmployeeApi from "../../app/useEmployeeApi";
import { showNotification } from "../../utils/notification";
import { t } from "i18next";
import RiskFactorsSelect from "../profession/RiskFactorsSelect";
import ConfirmationDialog from "../global/ConfirmationDialog";
import useProfessionApi from "../../app/useProfessionApi";
import useDebounce from "../../utils/useDebounce";

function EmployeeEdit({ employee, riskFactorNames, climaticNames, onSave, onDelete, onCancel }) {
    const employeeApi = useEmployeeApi();
    const professionApi = useProfessionApi();

    const [firstName, setFirstName] = useState(employee?.first_name || "");
    const [lastName, setLastName] = useState(employee?.last_name || "");
    const [employeeNumber, setEmployeeNumber] = useState(employee?.employee_number || "");

    const [department, setDepartment] = useState(employee?.department || "");
    const [departments, setDepartments] = useState([]);

    const [profession, setProfession, professionDebounced] = useDebounce(employee?.profession || "", 500);
    const [professions, setProfessions] = useState([]);

    const [birthDate, setBirthDate] = useState(employee?.birth_date || "");
    const [admissionDate, setAdmissionDate] = useState(employee?.admission_date || "");
    const [lastChangeDate, setLastChangeDate] = useState(employee?.last_change_date || "");
    const [sex, setSex] = useState(employee?.sex || "");
    const [height, setHeight] = useState(employee?.height || 170);
    const [weight, setWeight] = useState(employee?.weight || 60);

    const [climaticSummer, setClimaticSummer] = useState(employee?.climatic_summer || "");
    const [climaticWinter, setClimaticWinter] = useState(employee?.climatic_winter || "");
    const [riskFactors, setRiskFactors] = useState(employee?.risk_factors || []);
    const [riskFactorsSelectActive, setRiskFactorsSelectActive] = useState(false);

    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

    const reset = useCallback(() => {
        setFirstName(employee.first_name);
        setLastName(employee.last_name);
        setEmployeeNumber(employee.employee_number);
        setDepartment(employee.department);
        setProfession(employee.profession);
        setBirthDate(employee.birth_date);
        setAdmissionDate(employee.admission_date);
        setLastChangeDate(employee.last_change_date);
        setSex(employee.sex);
        setHeight(employee.height);
        setWeight(employee.weight);

        setClimaticSummer(employee.climatic_summer);
        setClimaticWinter(employee.climatic_winter);
        setRiskFactors(employee.risk_factors);
    }, [employee, setProfession]);

    const clear = useCallback(() => {
        setFirstName("");
        setLastName("");
        setEmployeeNumber("");
        setDepartment("");
        setProfession("");
        setBirthDate("01.01.2000");
        setAdmissionDate("01.01.2000");
        setLastChangeDate("01.01.2000");
        setSex("");
        setHeight(0);
        setWeight(0);

        setClimaticSummer("");
        setClimaticWinter("");
        setRiskFactors([]);
    }, [setProfession]);

    const onCancelClick = useCallback(() => {
        if (employee.id) {
            reset();
        } else {
            clear();
        }

        onCancel();
    }, [employee.id, onCancel, reset, clear]);

    const onSaveClick = useCallback(() => {
        onSave({
            ...employee,
            first_name: firstName,
            last_name: lastName,
            employee_number: employeeNumber,
            department: department,
            profession: profession,
            birth_date: birthDate,
            admission_date: admissionDate,
            last_change_date: lastChangeDate,
            sex: sex,
            height: height,
            weight: weight,

            climatic_summer: climaticSummer,
            climatic_winter: climaticWinter,
            risk_factors: riskFactors
        });

        // TODO: handle error

        onCancel();
    }, [onSave, employee, firstName, lastName, employeeNumber, department, profession, birthDate, admissionDate, lastChangeDate, sex, height, weight, climaticSummer, climaticWinter, riskFactors, onCancel]);

    const riskFactorsJustNames = useMemo(() => riskFactorNames.map((riskFactor) => riskFactor.name), [riskFactorNames]);

    const selectedRiskFactors = useMemo(() => {
        return riskFactors
            .map((codeName) => riskFactorNames.find((rf) => rf.codeName === codeName)?.name)
            .filter((riskFactor) => riskFactor !== undefined);
    }, [riskFactorNames, riskFactors]);

    const setSelectedRiskFactors = useCallback((selectedRiskFactors) => {
        setRiskFactors(selectedRiskFactors
            .map((name) => riskFactorNames.find((rf) => rf.name === name)?.codeName)
            .filter((riskFactor) => riskFactor !== undefined));
    }, [riskFactorNames]);

    const changeProfession = useCallback(async (profession) => {
        // Load new risk factors
        try {
            const selection = await professionApi.getProfessionSelection(profession);
            setSelectedRiskFactors(selection.riskFactors);
        } catch (error) {
            console.error(error);
            showNotification(t("employeeProfessionFetchError") + ": " + error.message, 'danger');
        }

        setProfession(profession.profession);
    }, [professionApi, setProfession, setSelectedRiskFactors]);

    useEffect(() => {
        async function fetchDepartments() {
            try {
                const departments = await employeeApi.getDepartments();
                setDepartments(departments);
            } catch (error) {
                console.error(error);
                showNotification(t("employeeDepartmentsFetchError") + ": " + error.message, 'danger');
            }
        }

        const delayedFetch = setTimeout(fetchDepartments, 200);
        return () => clearTimeout(delayedFetch);
    }, [employeeApi]);

    useEffect(() => {
        async function fetchProfessions(filterStr) {
            try {
                const p = await professionApi.getProfessionList(filterStr);
                setProfessions(p);
            } catch (error) {
                console.error(error);
                showNotification(t("employeeProfessionsFetchError") + ": " + error.message, 'danger');
            }
        }

        if (professionDebounced.length >= 3) {
            fetchProfessions(professionDebounced);
        } else {
            setProfessions([]);
        }
    }, [profession, professionApi, professionDebounced]);

    return (
        <Container>
            <Row>
                <Col xs={12} lg={6} className="mx-auto">
                    <Form>
                        <Form.Group className="mb-3" controlId="firstName">
                            <Form.Label><Trans>employeeFirstName</Trans></Form.Label>
                            <Form.Control
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="lastName">
                            <Form.Label><Trans>employeeLastName</Trans></Form.Label>
                            <Form.Control
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="employeeNumber">
                            <Form.Label><Trans>employeeNumber</Trans></Form.Label>
                            <Form.Control
                                type="text"
                                value={employeeNumber}
                                onChange={(e) => setEmployeeNumber(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="department">
                            <Form.Label><Trans>employeeDepartment</Trans></Form.Label>
                            <Form.Control
                                type="text"
                                value={department}
                                list="departmentList"
                                onChange={(e) => setDepartment(e.target.value)}
                            />
                            <datalist id="departmentList">
                                {departments.map((d) => <option key={d}>{d}</option>)}
                            </datalist>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="profession">
                            <Form.Label><Trans>employeeProfession</Trans></Form.Label>
                            <Form.Control
                                type="text"
                                value={profession}
                                list="professionList"
                                onChange={(e) => setProfession(e.target.value)}
                            />
                            {professions && (
                                <datalist id="professionList">
                                    {professions.map((p) => <option key={p.name + "." + p.industry} value={p.name}>{p.name} ({p.industry})</option>)}
                                </datalist>
                            )}

                            {/* <ProfessionSelect
                                size="sm"
                                active={professionSelectActive}
                                onEditing={setProfessionSelectActive}
                                selectedProfession={{ profession: profession }}
                                setSelectedProfession={(prof) => {
                                    changeProfession(prof);
                                    setProfessionSelectActive(false);
                                }}
                            /> */}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="birthDate">
                            <Form.Label><Trans>employeeBirthDate</Trans></Form.Label>
                            <Form.Control
                                type="date"
                                value={birthDate}
                                onChange={(e) => setBirthDate(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="admissionDate">
                            <Form.Label><Trans>employeeAdmissionDate</Trans></Form.Label>
                            <Form.Control
                                type="date"
                                value={admissionDate}
                                onChange={(e) => setAdmissionDate(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="lastChangeDate">
                            <Form.Label><Trans>employeeLastChangeDate</Trans></Form.Label>
                            <Form.Control
                                type="date"
                                value={lastChangeDate}
                                onChange={(e) => setLastChangeDate(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="sex">
                            <Form.Label><Trans>employeeSex</Trans></Form.Label>
                            <Form.Select
                                value={sex}
                                onChange={(e) => setSex(e.target.value)}
                            >
                                <option value=""><Trans>employeeSexNotSet</Trans></option>
                                <option value="MALE"><Trans>Male</Trans></option>
                                <option value="FEMALE"><Trans>Female</Trans></option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="height">
                            <Form.Label><Trans>employeeHeight</Trans></Form.Label>
                            <Form.Control
                                type="number"
                                value={height}
                                onChange={(e) => setHeight(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="weight">
                            <Form.Label><Trans>employeeWeight</Trans></Form.Label>
                            <Form.Control
                                type="number"
                                value={weight}
                                onChange={(e) => setWeight(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="climaticSummer">
                            <Form.Label><Trans>employeeClimaticSummer</Trans></Form.Label>
                            <Form.Select
                                value={climaticSummer}
                                onChange={(e) => setClimaticSummer(e.target.value)}
                            >
                                <option value=""><Trans>employeeClimaticNotSet</Trans></option>

                                {climaticNames?.map((climatic) => (
                                    <option key={climatic.codeName} value={climatic.codeName}>
                                        {climatic.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="climaticWinter">
                            <Form.Label><Trans>employeeClimaticWinter</Trans></Form.Label>
                            <Form.Select
                                value={climaticWinter}
                                onChange={(e) => setClimaticWinter(e.target.value)}
                            >
                                <option value=""><Trans>employeeClimaticNotSet</Trans></option>

                                {climaticNames?.map((climatic) => (
                                    <option key={climatic.codeName} value={climatic.codeName}>
                                        {climatic.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="riskFactors">
                            <Form.Label><Trans>employeeRiskFactors</Trans></Form.Label>
                            <RiskFactorsSelect
                                riskFactors={riskFactorsJustNames}
                                active={riskFactorsSelectActive}
                                onEditing={setRiskFactorsSelectActive}
                                selectedRiskFactors={selectedRiskFactors}
                                setSelectedRiskFactors={setSelectedRiskFactors}
                            />
                        </Form.Group>

                        <div className="d-flex justify-content-center">
                            {employee.id !== undefined && (
                                <>
                                    <Button
                                        variant="danger"
                                        className="me-auto"
                                        onClick={() => setDeleteConfirmationOpen(true)}
                                    >
                                        <FontAwesomeIcon icon={faTrash} /> <Trans>Delete</Trans>
                                    </Button>

                                    <ConfirmationDialog
                                        show={deleteConfirmationOpen}
                                        title={t("employeeDelete")}
                                        message={t("employeeDeleteMessage")}
                                        confirmText={t("Delete")}
                                        variant="danger"
                                        onConfirm={() => {
                                            setDeleteConfirmationOpen(false);
                                            onDelete(employee.id);
                                        }}
                                        onCancel={() => setDeleteConfirmationOpen(false)}
                                    />
                                </>
                            )}

                            <Button
                                variant="secondary"
                                className="ms-auto me-2"
                                onClick={onCancelClick}
                            >
                                <Trans>Cancel</Trans>
                            </Button>

                            <Button
                                variant="primary"
                                className="me-auto"
                                onClick={onSaveClick}
                            >
                                <FontAwesomeIcon icon={faSave} /> <Trans>Save</Trans>
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default EmployeeEdit;
