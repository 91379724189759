import { useMemo, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Trans } from "react-i18next";
import ProfessionEdit from "./ProfessionEdit";
import ProfessionDetails from "./ProfessionDetails";

function ProfessionTableRow({ profession, allNorms, onSave, onDelete }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const isClient = useMemo(() => profession.client_id, [profession]);

    return [
        <tr key={profession.id} className={!isClient ? "table-secondary" : ""}>
            <td>{profession.name}</td>
            <td className="d-none d-md-table-cell">{profession.industry}</td>
            <td>{profession.norm}</td>
            <td>
                {isClient && (
                    <Button
                        variant="link"
                        size="sm"
                        onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
                    >{isExpanded ? <Trans>professionTableHide</Trans> : <Trans>professionTableShow</Trans>}</Button>
                )}
            </td>
        </tr>,
        isExpanded && (
            <tr key={`${profession.id}-details`}>
                <td colSpan={4}>
                    <Container>
                        {isEdit ? (
                            <ProfessionEdit
                                profession={profession}
                                onSave={onSave}
                                onDelete={onDelete}
                                onCancel={() => setIsEdit(false)}
                            />
                        ) : (
                            <ProfessionDetails
                                profession={profession}
                                onEdit={() => setIsEdit(true)}
                                onDelete={onDelete} />
                        )}
                    </Container>
                </td>
            </tr>
        )
    ];
}

export default ProfessionTableRow;
