import { useEffect, useState } from "react";
import { showNotification } from "../../utils/notification";
import { t } from "i18next";
import Loader from "../global/Loader";
import { Button, Container } from "react-bootstrap";
import ProfessionSelect from "../profession/ProfessionSelect";
import ClimaticSelect from "../profession/ClimaticSelect";
import { Trans } from "react-i18next";
import RiskFactorsSelect from "../profession/RiskFactorsSelect";
import ProtPropertyVariants from "../profession/ProtPropertyVariants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import useSessionStorageState from "../../utils/useSessionStorageState";
import { deepCompare } from "../../utils/object";
import useProfessionApi from "../../app/useProfessionApi";
import NormSelect from "./NormSelect";

const ProfessionSelectTab = () => {
    const navigate = useNavigate();

    const professionApi = useProfessionApi();

    const [normList, setNormList] = useState([]);
    const [riskFactorList, setRiskFactorList] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [protectivePropertiesList, setProtectivePropertiesList] = useState([]);
    const [climaticList, setClimaticList] = useState([]);

    const [professionActive, setProfessionActive] = useState(true);
    const [selectedProfession, setSelectedProfession] = useSessionStorageState("selectedProfession", null);

    const [climaticActive, setClimaticActive] = useState(false);
    const [selectedClimatic, setSelectedClimatic] = useSessionStorageState("selectedClimatic", null);

    const [selectedNorm, setSelectedNorm] = useState(null);

    const [riskFactorsActive, setRiskFactorsActive] = useState(false);
    const [selectedRiskFactors, setSelectedRiskFactors] = useSessionStorageState("selectedRiskFactors", null);

    const [selectedProtProperties, setSelectedProtProperties] = useState(null);
    const [protPropertyVariants, setProtPropertyVariants] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    function reset() {
        setSelectedProfession(null);
        setSelectedClimatic(null);
        setSelectedNorm(null);
        setSelectedRiskFactors(null);
        setSelectedProtProperties(null);
        setProtPropertyVariants(null);
    }

    function handleProfessionSelected(profession) {
        // On profession change, clear other fields
        setSelectedClimatic(null);
        setSelectedNorm(null);
        setSelectedRiskFactors(null);
        setSelectedProtProperties(null);
        setProtPropertyVariants(null);

        setSelectedProfession(profession);
    }

    function handleClimaticSelected(climatic) {
        setSelectedNorm(null);
        setSelectedRiskFactors(null);
        setSelectedProtProperties(null);
        setProtPropertyVariants(null);

        setSelectedClimatic(climatic);
    }

    function handleRiskFactorsSelected(riskFactors) {
        setSelectedProtProperties(null);
        setProtPropertyVariants(null);

        setSelectedRiskFactors(riskFactors);
    }

    function fitShoes() {
        // Pass selected properties in state
        navigate("/catalog", {
            state: {
                selectedProperties: {
                    climaticConditions: [selectedClimatic],
                    // riskFactors: selectedRiskFactors,
                    protectiveProperties: selectedProtProperties,
                }
            }
        });
    }

    useEffect(() => {
        async function selectForProfession(profession, climatic, riskFactors) {
            try {
                const selection = await professionApi.getProfessionSelection(profession, climatic, riskFactors);

                setSelectedNorm(selection.norm);

                if (!deepCompare(selectedRiskFactors, selection.riskFactors)) {
                    setSelectedRiskFactors(selection.riskFactors);
                }

                setSelectedProtProperties(selection.protectiveProperties);
                setProtPropertyVariants(selection.protectivePropertyVariants);
            } catch (err) {
                console.log(err);
                showNotification(t("professionErrorSelecting") + ": " + err, "danger");
            }
        }

        if (!selectedProfession) {
            setProfessionActive(true);
            setClimaticActive(false);
            setRiskFactorsActive(false);
        } else if (!selectedClimatic) {
            setProfessionActive(false);
            setClimaticActive(true);
            setRiskFactorsActive(false);
        } else {
            selectForProfession(selectedProfession, selectedClimatic, selectedRiskFactors);

            setProfessionActive(false);
            setClimaticActive(false);
            setRiskFactorsActive(false);
        }
    }, [normList, professionApi, selectedClimatic, selectedNorm, selectedProfession, selectedRiskFactors, setSelectedRiskFactors]);

    useEffect(() => {
        async function fetchLists() {
            setIsLoading(true);

            try {
                const normList = await professionApi.getNormList();
                const riskFactorList = await professionApi.getRiskFactorList();
                const protectivePropertyList = await professionApi.getProtectivePropertyList();
                const climaticList = await professionApi.getClimaticList();

                setNormList(normList);
                setRiskFactorList(riskFactorList);
                setProtectivePropertiesList(protectivePropertyList);
                setClimaticList(climaticList);

                setIsLoading(false);
            } catch (err) {
                console.log(err);
                showNotification(t("professionErrorLoadingData") + ": " + err, "danger");
                setIsLoading(false);
            }
        }

        fetchLists();
    }, [professionApi]);

    return (
        <>
            {isLoading ? (
                <Loader text={t("professionLoading")} />
            ) : (
                <Container>
                    <h3 className="my-3">1. {t("professionTitle")}</h3>
                    <div className="my-3">
                        <p><Trans>professionDescription</Trans></p>
                        <ProfessionSelect active={professionActive} onEditing={setProfessionActive} selectedProfession={selectedProfession} setSelectedProfession={handleProfessionSelected} />
                    </div>

                    {selectedProfession && (
                        <>
                            <h3 className="my-3">2. {t("professionClimaticTitle")}</h3>
                            <p><Trans>professionClimaticDescription</Trans></p>
                            <div className="my-3">
                                <ClimaticSelect climaticList={climaticList} active={climaticActive} onEditing={setClimaticActive} selectedClimatic={selectedClimatic} setSelectedClimatic={handleClimaticSelected} />
                            </div>
                        </>
                    )}

                    {selectedNorm !== null && (
                        <>
                            <h3 className="my-3">3. {t("etnReqsTitle")}</h3>
                            <p><Trans>etnReqsDescription</Trans></p>
                            <div className="my-3">
                                <NormSelect normList={normList} selectedNorm={selectedNorm} />
                            </div>
                        </>
                    )}

                    {selectedRiskFactors !== null && (
                        <>
                            <h3 className="my-3">4. {t("riskFactorsTitle")}</h3>
                            <p><Trans>riskFactorsDescription</Trans></p>
                            <div className="my-3">
                                <RiskFactorsSelect riskFactors={riskFactorList} active={riskFactorsActive} onEditing={setRiskFactorsActive} selectedRiskFactors={selectedRiskFactors} setSelectedRiskFactors={handleRiskFactorsSelected} />
                            </div>
                        </>
                    )}

                    {protPropertyVariants !== null && (
                        <>
                            <h3 className="my-3">5. {t("protectivePropertyVariantsTitle")}</h3>
                            <p><Trans>protectivePropertyVariantsDescription</Trans></p>
                            <div className="my-3">
                                <ProtPropertyVariants selectedProtProperties={selectedProtProperties} setSelectedProtProperties={setSelectedProtProperties} protPropertyVariants={protPropertyVariants} setProtPropertyVariants={setProtPropertyVariants} />
                            </div>
                        </>
                    )}

                    <div className="my-3">
                        {selectedClimatic && selectedProtProperties && selectedRiskFactors && (
                            <div className="d-flex justify-content-center">
                                <Button variant="primary" size="lg" className="mx-3" onClick={fitShoes}>
                                    <FontAwesomeIcon icon={faCircleChevronRight} /> <Trans>professionFitShoe</Trans>
                                </Button>
                            </div>
                        )}

                        <div className="d-flex justify-content-center mt-2">
                            <Button variant="outline-secondary" className="mx-3" onClick={reset}>
                                <FontAwesomeIcon icon={faXmark} /> <Trans>professionReset</Trans>
                            </Button>
                        </div>
                    </div>
                </Container>
            )}
        </>
    )
};

export default ProfessionSelectTab;
