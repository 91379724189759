import { Trans } from "react-i18next";
import { useEffect, useState } from "react";
import RiskFactorTableRow from "./RiskFactorTableRow";
import useProfessionApi from "../../app/useProfessionApi";
import { showNotification } from "../../utils/notification";
import { t } from "i18next";

function RiskFactorTable({ riskFactors, showClientOnly, onSave, onDelete }) {
    const professionApi = useProfessionApi();
    const [allProtProperties, setAllProtProperties] = useState(null);

    useEffect(() => {
        const fetchAllProtProperties = async () => {
            try {
                const p = await professionApi.getProtectivePropertyList(true);
                setAllProtProperties(p);
            } catch (e) {
                console.error(e);
                showNotification(t("errorLoadingProtectiveProperties"), "danger");
            }
        }

        const delayedFetch = setTimeout(() => {
            fetchAllProtProperties();
        }, 200);

        return () => clearTimeout(delayedFetch);
    }, [professionApi]);

    return (
        <table className="table table-hover mt-3">
            <thead>
                <tr>
                    <th scope="col"><Trans>riskFactorTableRiskFactor</Trans></th>
                    <th scope="col" className="d-none d-md-table-cell"><Trans>riskFactorTableProtProperties</Trans></th>
                    <th scope="col"><Trans>riskFactorTableAction</Trans></th>
                </tr>
            </thead>

            <tbody>
                {riskFactors.length > 0 ? riskFactors.map((riskFactor) => (
                    (!showClientOnly || riskFactor.client_id) && (
                        <RiskFactorTableRow riskFactor={riskFactor} allProtProperties={allProtProperties} key={riskFactor.id} onSave={onSave} onDelete={onDelete} />
                    )
                )) : (
                    <tr>
                        <td colSpan={3}><Trans>riskFactorTableEmpty</Trans></td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

export default RiskFactorTable;