import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faRemove, faSearch } from '@fortawesome/free-solid-svg-icons';
import { t } from "i18next";
import { Trans } from "react-i18next";
import { Button, Spinner } from "react-bootstrap";
import { useMemo, useState } from "react";
import ConfirmationDialog from "../global/ConfirmationDialog";
import '../../styles/Utils.scss';

const MeasurementCard = ({ measurement, handleRemove, employee, handleLink }) => {
    const humanName = useMemo(() => {
        if (employee?.first_name && employee?.last_name) {
            return employee.first_name + " " + employee.last_name;
        } else if (measurement?.humanInfo?.name) {
            return measurement.humanInfo.name;
        } else {
            return "";
        }
    }, [measurement, employee]);

    const name = useMemo(() => {
        if (humanName !== "") {
            return humanName;
        } else {
            return t("Measurement") + " " + measurement.measurementId;
        }
    }, [humanName, measurement]);

    const dateString = useMemo(() => measurement.dateString, [measurement]);

    const leftRightText = useMemo(() => {
        if (measurement.left && measurement.right) {
            return t("Left and right feet");
        } else if (measurement.left) {
            return t("Left foot");
        } else if (measurement.right) {
            return t("Right foot");
        } else {
            return t("mcNoFeet");
        }
    }, [measurement]);

    const imageUrl = useMemo(() => {
        if (measurement?.imageFixed) {
            return measurement.imageFixed;
        } else if (measurement?.smallImagesFixed?.[0]) {
            return measurement.smallImagesFixed[0];
        } else {
            return "/measurement-sketch.jpg";
        }
    }, [measurement]);

    const inProcess = useMemo(() => {
        return !(measurement.left || measurement.right);
    }, [measurement]);

    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

    return (
        <div className={inProcess ? "card h-100 w-100 bg-construction border-construction" : "card h-100 w-100"}>
            {imageUrl && (
                inProcess ? (
                    <img className="card-img-top meas-card" src={imageUrl} alt={name} />
                ) : (
                    <a href={"/measurement?" + new URLSearchParams({ measurementId: measurement.measurementId })} >
                        <img className="card-img-top meas-card" src={imageUrl} alt={name} />
                    </a>
                )
            )}
            <div className="card-body">
                <div className="d-flex justify-content-between">
                    <h5 className="card-title">{name}</h5>

                    {handleLink && (
                        <Button className="" variant="primary" size="sm" style={{ border: 'none' }} onClick={() => handleLink(measurement.measurementId)}>
                            <FontAwesomeIcon icon={faLink} /> <Trans>measurementCardLink</Trans>
                        </Button>
                    )}

                    {employee && (
                        <FontAwesomeIcon icon={faLink} />
                    )}
                </div>
                <p className="card-text text-truncate">
                    {dateString && (
                        <>
                            <Trans>Date</Trans>: {dateString}<br />
                        </>
                    )}
                    {leftRightText}<br />
                    <span className="text-muted small"><Trans>Measurement ID</Trans>: {measurement.measurementId}</span>
                </p>

                <div className="d-flex justify-content-end">
                    {handleRemove && (
                        <>
                            <Button className="me-auto" variant="outline-danger" size="sm" style={{ border: 'none' }} onClick={() => setDeleteConfirmationOpen(true)}>
                                <FontAwesomeIcon icon={faRemove} />
                            </Button>

                            <ConfirmationDialog
                                show={deleteConfirmationOpen}
                                title={t("Delete measurement")}
                                message={t("Are you sure you want to delete this measurement?")}
                                confirmText={t("Delete")}
                                variant="danger"
                                onConfirm={() => {
                                    setDeleteConfirmationOpen(false);
                                    handleRemove(measurement.measurementId);
                                }}
                                onCancel={() => setDeleteConfirmationOpen(false)}
                            />
                        </>
                    )}

                    {(measurement.left || measurement.right) ? (
                        <a href={"/catalog?" + new URLSearchParams({ measurementId: measurement.measurementId, humanName: humanName })}
                            className="btn btn-primary"><FontAwesomeIcon icon={faSearch} /> <Trans>Fit</Trans></a>
                    ) : (
                        <Button variant="primary" disabled><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> <Trans>mcShooting</Trans></Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MeasurementCard;
