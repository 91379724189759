import { t } from "i18next";
import { Helmet } from "react-helmet-async";
import Header from '../components/global/Header';
import Footer from '../components/global/Footer';
import { Container, Tab, Tabs } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import ProfessionSelectTab from "../components/profession/ProfessionSelectTab";
import ProfessionTab from "../components/profession/ProfessionTab";
import NormTab from "../components/profession/NormTab";
import RiskFactorTab from "../components/profession/RiskFactorTab";
import useLocalStorageState from "use-local-storage-state";

const Professions = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [sessionId] = useLocalStorageState("sessionId", null);
    
    // Extract the active tab from the URL query parameters
    const queryParams = new URLSearchParams(location.search);
    const activeTab = queryParams.get('tab') || 'professionSelectTab';

    const handleTabSelect = (key) => {
        // Update the URL when a tab is selected
        navigate(`?tab=${key}`);
    };

    return (
        <>
            <Helmet>
                <title>{t("professionTitle")}</title>
            </Helmet>

            <Header />

            <Container>
                <Tabs defaultActiveKey="professionSelectTab" activeKey={activeTab} onSelect={handleTabSelect} mountOnEnter className="mt-3">
                    <Tab eventKey="professionSelectTab" title={t("professionSelectTab")}>
                        <ProfessionSelectTab />
                    </Tab>

                    <Tab eventKey="professionTab" title={t("professionTab")} disabled={!sessionId}>
                        <ProfessionTab />
                    </Tab>

                    <Tab eventKey="normTab" title={t("normTab")} disabled={!sessionId}>
                        <NormTab />
                    </Tab>

                    <Tab eventKey="riskFactorTab" title={t("riskFactorTab")} disabled={!sessionId}>
                        <RiskFactorTab />
                    </Tab>
                </Tabs>
            </Container>

            <Footer />
        </>
    )
};

export default Professions;
