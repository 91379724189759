import { Helmet } from "react-helmet-async";
import Header from "../components/global/Header";
import { t } from "i18next";
import { Button, Col, Collapse, Container, Row } from "react-bootstrap";
import { Trans } from "react-i18next";
import Footer from "../components/global/Footer";
import { useLocation, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { showNotification } from "../utils/notification";
import ClientApi from "../app/clientApi";
import useLocalStorageState from "use-local-storage-state";
import LoginRegister from "../components/profile/LoginRegister";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVk } from "@fortawesome/free-brands-svg-icons";
import LoginResetPassword from "../components/profile/LoginResetPassword";

function Login() {
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [sessionId, setSessionId] = useLocalStorageState('sessionId', { defaultValue: null });
    const clientApi = useMemo(() => new ClientApi(sessionId), [sessionId]);

    const [registrationFormOpen, setRegistrationFormOpen] = useState(false);
    const [resetPasswordOpen, setResetPasswordOpen] = useState(false);

    const loginVk = useCallback(() => {
        async function prepareVkLogin() {
            const authUrl = await clientApi.prepareVkLogin(window.location.origin, location.pathname);
            window.location.href = authUrl;
        }

        prepareVkLogin()
            .then(() => {
                console.log("Login prepared");
            })
            .catch((error) => {
                console.log(error);
                showNotification(t("loginVkFailed") + ": " + error.message, 'danger');
            });
    }, [clientApi, location.pathname]);

    useEffect(() => {
        async function performVkAuth() {
            const code = searchParams.get('code');
            const type = searchParams.get('type');
            const state = searchParams.get('state');
            const device_id = searchParams.get('device_id');

            const session_id = await clientApi.performVkAuth(code, type, state, device_id);
            setSessionId(session_id);

            // Redirect to Profile page
            window.location.href = '/profile';
        }

        async function processVkAuth() {
            const code = searchParams.get('code');
            const state = searchParams.get('state');
            const device_id = searchParams.get('device_id');
            if (code && state && device_id) {
                try {
                    await performVkAuth();
                    console.log("Auth successful");
                } catch (error) {
                    console.log(error);
                    showNotification(t("loginVkFailed") + ": " + error.message, 'danger');
                }
            }

            const session_id = searchParams.get('session_id');
            if (session_id) {
                console.log("Session ID:", session_id);
                // Store session in session storage
                setSessionId(session_id);

                // Redirect to main page
                window.location.href = '/';
            }
        }

        async function processLoginAs() {
            const searchSessionId = searchParams.get('session_id');
            if (searchSessionId) {
                console.log("Session ID:", searchSessionId);
                // Store session in session storage
                setSessionId(searchSessionId);

                // Redirect to main page
                window.location.href = '/';
            }
        }

        const delayedProcessAuth = setTimeout(() => {
            processVkAuth();
            processLoginAs();
        }, 200);

        return () => clearTimeout(delayedProcessAuth);
    }, [clientApi, searchParams, setSessionId]);

    const loginPassword = useCallback(() => {
        async function performPasswordLogin() {
            const session_id = await clientApi.passwordLogin({ email, password });
            setSessionId(session_id);

            // Redirect to Profile page
            window.location.href = '/profile';
        }

        performPasswordLogin()
            .then(() => {
                console.log("Login successful");
            })
            .catch((error) => {
                console.log(error);
                showNotification(t("loginPasswordFailed") + ": " + error.message, 'danger');
            });
    }, [clientApi, email, password, setSessionId]);

    const handleRegData = useCallback((regData) => {
        async function performPasswordRegister() {
            const session_id = await clientApi.passwordRegister(regData);
            setSessionId(session_id);

            // Redirect to Profile page
            window.location.href = '/profile';
        }

        performPasswordRegister()
            .then(() => {
                console.log("Registration successful");
            })
            .catch((error) => {
                console.log(error);
                showNotification(t("loginPasswordFailed") + ": " + error.message, 'danger');
            });
    }, [clientApi, setSessionId]);

    return (
        <>
            <Helmet>
                <title>{t("Catalog")} - FeetApp</title>
            </Helmet>

            <Header />

            <Container>
                <Row>
                    <Col md={6} className="mx-auto">
                        <h3 className="mt-5"><Trans>loginWitkVk</Trans></h3>

                        <p className="mt-3"><Trans>loginWithVkRegisterInfo</Trans></p>

                        <Button variant="primary" size="lg" onClick={() => loginVk()} className="mt-3"><FontAwesomeIcon icon={faVk} /> <Trans>Login with VK</Trans></Button>

                        <h3 className="mt-5"><Trans>loginWithPassword</Trans></h3>

                        <p className="mt-3"><Trans>loginWithPasswordAlreadyRegister</Trans></p>

                        <div className="mt-3 align-items-center">
                            <Row>
                                <Col xs={12} md={4} className="align-self-center">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t("Email")}
                                        value={email}
                                        onChange={(event) => setEmail(event.target.value)}
                                    />
                                </Col>
                                <Col xs={12} md={4} className="align-self-center">
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder={t("Password")}
                                        value={password}
                                        onChange={(event) => setPassword(event.target.value)}
                                    />
                                </Col>
                                <Col xs={12} md={4} className="align-self-center">
                                    <Button variant="primary" onClick={() => loginPassword()}><Trans>loginByPassword</Trans></Button>
                                </Col>
                            </Row>
                        </div>

                        <div className="mt-2">
                            <Button variant="link" onClick={() => setResetPasswordOpen((resetPasswordOpen) => !resetPasswordOpen)}><Trans>forgotPassword</Trans></Button>
                        </div>

                        <Collapse in={resetPasswordOpen} className="mt-2">
                            <div>
                                <LoginResetPassword clientApi={clientApi} />
                            </div>
                        </Collapse>

                        <p className="mt-4"><Trans>loginWithPasswordNotRegister</Trans></p>

                        <div className="mt-2">
                            <Button variant="link" onClick={() => setRegistrationFormOpen((registrationFormOpen) => !registrationFormOpen)}><Trans>createAccount</Trans></Button>
                        </div>

                        <Collapse in={registrationFormOpen} className="mt-3">
                            <div>
                                <LoginRegister clientApi={clientApi} handleRegData={handleRegData} />
                            </div>
                        </Collapse>
                    </Col>
                </Row>
            </Container>

            <Footer />
        </>
    );
}

export default Login;
