import { useCallback, useEffect, useState } from "react";
import { showNotification } from "../../utils/notification";
import { t } from "i18next";
import Loader from "../global/Loader";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { Trans } from "react-i18next";
import RiskFactorTable from "./RiskFactorTable";
import RiskFactorEdit from "./RiskFactorEdit";
import useClientProfessionApi from "../../app/useClientProfessionApi";
import useDebounce from "../../utils/useDebounce";
import FilterStringInput from "../global/FilterStringInput";

function RiskFactorTab() {
    const clientRiskApi = useClientProfessionApi();

    const [riskFactors, setRiskFactors] = useState(null);
    const [reloadRequest, setReloadRequest] = useState(false);

    const [filterStr, setFilterStr, debouncedFilterStr] = useDebounce("", 500);
    const [loading, setLoading] = useState(false);

    const [showClientOnly, setShowClientOnly] = useState(true);
    const [addFormOpen, setAddFormOpen] = useState(false);

    const onSave = useCallback(async (riskFactor) => {
        if (riskFactor.id) {
            try {
                const updatedRiskFactor = await clientRiskApi.updateRiskFactor(riskFactor);

                // Replace risk factors in list
                const newRiskFactors = riskFactors.map((rf) => {
                    if (rf.id === riskFactor.id) {
                        return updatedRiskFactor;
                    }
                    return rf;
                });
                setRiskFactors(newRiskFactors);
                showNotification(t("riskFactorTableUpdateSuccess"), 'success');
            } catch (error) {
                console.error(error);
                showNotification(t("riskFactorTableUpdateError") + ": " + error.message, 'danger');
            }
        } else {
            try {
                const rf = await clientRiskApi.addRiskFactor(riskFactor);

                // Add new risk factor to list
                setRiskFactors([...riskFactors, rf]);
                showNotification(t("riskFactorTableCreateSuccess"), 'success');
            } catch (error) {
                console.error(error);
                showNotification(t("riskFactorTableCreateError") + ": " + error.message, 'danger');
            }
        }
    }, [clientRiskApi, riskFactors]);

    const onDelete = useCallback(async (id) => {
        try {
            await clientRiskApi.deleteRiskFactor({ id });

            // Remove risk factor from list
            const newRiskFactors = riskFactors.filter((rf) => rf.id !== id);
            setRiskFactors(newRiskFactors);
            showNotification(t("riskFactorTableDeleteSuccess"), 'success');
        } catch (error) {
            console.error(error);
            showNotification(t("riskFactorTableDeleteError") + ": " + error.message, 'danger');
        }
    }, [clientRiskApi, riskFactors]);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);

            try {
                const data = await clientRiskApi.getRiskFactors(showClientOnly, debouncedFilterStr);
                setRiskFactors(data);
            } catch (error) {
                console.error(error);
                showNotification(t("riskFactorTableFetchError") + ": " + error.message, 'danger');
            }

            setLoading(false);
        }

        const delayedFetch = setTimeout(fetchData, 200);
        return () => clearTimeout(delayedFetch);
    }, [clientRiskApi, debouncedFilterStr, reloadRequest, showClientOnly]);

    return (
        riskFactors === null ? (
            <Loader />
        ) : (
            <div className="mt-4">
                {/* Buttons toolbar */}
                <div className="mt-3 d-flex justify-content-start align-items-center gap-2">
                    <Button
                        variant="primary"
                        onClick={() => setReloadRequest((prev) => !prev)}>
                        <FontAwesomeIcon icon={faRefresh} /> <Trans>riskFactorTableReload</Trans>
                    </Button>

                    <div className="flex-grow-1">
                        <FilterStringInput filterStr={filterStr} setFilterStr={setFilterStr} />
                    </div>

                    <Form.Check
                        type="switch"
                        id="showClientOnly"
                        label={t("riskFactorTableShowClientOnly")}
                        checked={showClientOnly}
                        onChange={(e) => setShowClientOnly(e.target.checked)}
                    />
                </div>

                {/* Main table */}
                {!loading ? (
                    <RiskFactorTable riskFactors={riskFactors} showClientOnly={showClientOnly} onSave={onSave} onDelete={onDelete} />
                ) : (
                    <Loader />
                )}

                {/* Add form */}
                <div className="d-flex justify-content-center mt-3">
                    <Button
                        variant="primary"
                        onClick={() => setAddFormOpen(true)}>
                        <FontAwesomeIcon icon={faPlus} /> <Trans>riskFactorTableAdd</Trans>
                    </Button>
                </div>

                {addFormOpen && (
                    <RiskFactorEdit
                        riskFactor={{}}
                        onSave={onSave}
                        onCancel={() => setAddFormOpen(false)}
                    />
                )}
            </div>
        )
    );
}

export default RiskFactorTab;
