import { useCallback, useEffect, useMemo, useState } from "react";

import { Trans } from "react-i18next";
import { t } from "i18next";

import { showNotification } from "../../utils/notification";
import Loader from "../global/Loader";
import { apiAddMeasurementToCart, apiGetCartMeasurements, apiRemoveMeasurementFromCart } from "../../app/measurementApi";
import AddMeasurementForm from "./AddMeasurementForm";
import { Button, Collapse } from "react-bootstrap";
import useCartData from "../../app/useCartData";
import useCartApi from "../../app/useCartApi";
import ExportMeasurementsForm from "./ExportMeasurementsForm";
import MeasurementCards from "./MeasurementCards";

const CartMeasurements = () => {
    const { cart } = useCartData();
    const cartApi = useCartApi();

    const [isLoading, setIsLoading] = useState(false);
    const [measurements, setMeasurements] = useState([]);

    const cartId = useMemo(() => cart?.cartId, [cart]);

    const [exportMeasurementsOpen, setExportMeasurementsOpen] = useState(false);

    const updateMeasurements = useCallback(async (backgroundUpdate) => {
        if (!cartId) {
            return;
        }

        if (!backgroundUpdate) {
            setIsLoading(true);
        }

        try {
            // fetch finished measurements
            let data = await apiGetCartMeasurements(cartId, true);

            // fetch non-finished measurements for last 5 minutes
            const data2 = await apiGetCartMeasurements(cartId, false, 5 * 60);
            data.push(...data2);

            if (!backgroundUpdate) {
                setIsLoading(false);
            }

            setMeasurements(data);
        } catch (err) {
            console.log(err);
            showNotification(t("Error loading cart measurements") + ": " + err, 'danger');

            if (!backgroundUpdate) {
                setIsLoading(false);
            }
        }
    }, [cartId]);

    async function addMeasurement(measurementId) {
        if (!cartId) {
            showNotification(t("cmNoCart"), 'danger');
            return;
        }

        try {
            await apiAddMeasurementToCart(measurementId, cartId)
        } catch (err) {
            console.log(err);
            showNotification(t("cmAddToCartFailed") + ": " + err, 'danger');
            return;
        }

        await updateMeasurements();
    }

    async function removeMeasurement(measurementId) {
        if (!cartId) {
            showNotification(t("cmNoCart"), 'danger');
            return;
        }

        try {
            await apiRemoveMeasurementFromCart(measurementId, cartId)
        } catch (err) {
            console.log(err);
            showNotification(t("cmRemoveFromCartFailed") + ": " + err, 'danger');
            return;
        }

        await updateMeasurements();
    }

    useEffect(() => {
        if (cartId) {
            updateMeasurements();

            // Update timer
            const timer = setInterval(() => {
                updateMeasurements(true);
            }, 5 * 1000);

            return () => clearInterval(timer);
        }
    }, [cartId, updateMeasurements]);

    // Early returns
    if (!cartId) {
        return (
            <div>
                <div className="alert alert-primary my-3" role="alert">
                    <p><Trans>Synchronize shopping list to view synchronized measurements.</Trans></p>
                </div >
            </div >
        )
    }

    if (isLoading) {
        return <Loader />
    }

    // Main render
    return (
        <>
            <MeasurementCards measurements={measurements} handleRemove={removeMeasurement} />

            <div className="d-flex justify-content-between align-items-center mt-3">
                <AddMeasurementForm handleAdded={addMeasurement} />

                {cartId && measurements.length > 0 && (
                    <Button variant="secondary" onClick={() => setExportMeasurementsOpen(!exportMeasurementsOpen)}><Trans>exportMeasurements</Trans></Button>
                )}
            </div>

            <div className="d-flex justify-content-end">
                <Collapse in={exportMeasurementsOpen} className="mt-3">
                    <div id="exportMeasurements">
                        <ExportMeasurementsForm cartApi={cartApi} cartId={cartId} />
                    </div>
                </Collapse>
            </div>
        </>
    );
};

export default CartMeasurements;
