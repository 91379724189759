import { Trans } from "react-i18next";
import useClientMeasurementApi from "../../app/useClientMeasurementApi";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getInformativeMeasurements } from "../../app/measurement";
import { apiGetMeasurements } from "../../app/measurementApi";
import { showNotification } from "../../utils/notification";
import { t } from "i18next";
import Loader from "../global/Loader";
import { Alert, Button, Collapse, Modal } from "react-bootstrap";
import AddMeasurementForm from "./AddMeasurementForm";
import EmployeeSelect from "../employee/EmployeeSelect";
import useEmployeeApi from "../../app/useEmployeeApi";
import ExportMeasurementsForm from "./ExportMeasurementsForm";
import MeasurementCards from "./MeasurementCards";

function ClientMeasurements({ measurementIds, employees }) {
    const clientMeasurementApi = useClientMeasurementApi();
    const employeeApi = useEmployeeApi();

    const [measurements, setMeasurements] = useState(null);
    const informativeMeasurements = useMemo(() => getInformativeMeasurements(measurements), [measurements]);

    const [newMeasurementsAdded, setNewMeasurementsAdded] = useState(false);
    const [exportMeasurementsOpen, setExportMeasurementsOpen] = useState(false);

    const [employeeSelectOpen, setEmployeeSelectOpen] = useState(false);
    const [employeeSelectMeasurement, setEmployeeSelectMeasurement] = useState(null);

    const addNewMeasurements = useCallback(async (clientMeasurementIds) => {
        if (!clientMeasurementApi.ready()) {
            return;
        }

        if (!measurementIds || newMeasurementsAdded) {
            return;
        }

        const newIds = measurementIds.split(',');
        let mIds = clientMeasurementIds;

        // Add all measurements that are not in the list
        for (const m in newIds) {
            if (!mIds.includes(newIds[m])) {
                await clientMeasurementApi.addMeasurement(newIds[m], true);
                mIds.push(newIds[m]);
            }
        }

        setNewMeasurementsAdded(true);
        return mIds;
    }, [clientMeasurementApi, measurementIds, newMeasurementsAdded]);

    const updateMeasurements = useCallback(async () => {
        if (!clientMeasurementApi.ready()) {
            return;
        }

        try {
            // Fetch client measurements IDs
            let mIds = await clientMeasurementApi.getMeasurements();

            // Add new measurements if given
            if (measurementIds && !newMeasurementsAdded) {
                mIds = await addNewMeasurements(mIds);
            }

            // Fetch measurements
            const ms = await apiGetMeasurements(mIds);
            setMeasurements(ms);
        } catch (err) {
            console.log(err);
            showNotification(t("clientMeasurementsFetchFailed") + ": " + err, 'danger');
        }
    }, [addNewMeasurements, clientMeasurementApi, measurementIds, newMeasurementsAdded]);

    const addMeasurement = useCallback(async (measurementId) => {
        if (!clientMeasurementApi.ready()) {
            showNotification(t("clientMeasurementsNotReady"), 'danger');
            return;
        }

        try {
            await clientMeasurementApi.addMeasurement(measurementId);
            await updateMeasurements();
        } catch (err) {
            console.log(err);
            showNotification(t("clientMeasurementsAddFailed") + ": " + err, 'danger');
        }
    }, [clientMeasurementApi, updateMeasurements]);

    const selectMeasurement = useCallback((measurement) => {
        setEmployeeSelectMeasurement(measurement);
        setEmployeeSelectOpen(true);
    }, [setEmployeeSelectMeasurement, setEmployeeSelectOpen]);

    const bindMeasurement = useCallback(async (employee) => {
        if (!employeeApi.ready()) {
            showNotification(t("employeesNotReady"), 'danger');
            return;
        }

        try {
            await employeeApi.addEmployeeMeasurement(employee.id, employeeSelectMeasurement);
            await updateMeasurements();
        } catch (err) {
            console.log(err);
            showNotification(t("employeesAddMeasurementFailed") + ": " + err, 'danger');
        }

        setEmployeeSelectMeasurement(null);
        setEmployeeSelectOpen(false);
    }, [employeeApi, employeeSelectMeasurement, updateMeasurements]);

    const deleteMeasurement = useCallback(async (measurementId) => {
        if (!clientMeasurementApi.ready()) {
            showNotification(t("clientMeasurementsNotReady"), 'danger');
            return;
        }

        try {
            await clientMeasurementApi.deleteMeasurement(measurementId);
            await updateMeasurements();
        } catch (err) {
            console.log(err);
            showNotification(t("clientMeasurementsRemoveFailed") + ": " + err, 'danger');
        }
    }, [clientMeasurementApi, updateMeasurements]);

    useEffect(() => {
        if (clientMeasurementApi.ready()) {
            updateMeasurements();

            // Update timer
            const timer = setInterval(() => {
                updateMeasurements();
            }, 5000);
            return () => clearInterval(timer);
        }
    }, [clientMeasurementApi, updateMeasurements]);

    return (
        measurements === null ? (
            <Loader />
        ) : (
            <>
                <Modal show={employeeSelectOpen} onHide={() => setEmployeeSelectOpen(false)}>
                    <Modal.Header>
                        <Modal.Title><Trans>selectEmployee</Trans></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EmployeeSelect
                            employees={employees}
                            active={true}
                            handleActive={setEmployeeSelectOpen}
                            handleSelected={bindMeasurement} />
                    </Modal.Body>
                </Modal>

                {newMeasurementsAdded && (
                    <Alert variant="success" className="my-3">
                        <Trans>Measurements added</Trans>
                    </Alert>
                )}

                <MeasurementCards
                    measurements={measurements}
                    handleRemove={deleteMeasurement}
                    handleLink={selectMeasurement} />

                <div className="d-flex justify-content-between align-items-center mt-3">
                    <AddMeasurementForm handleAdded={addMeasurement} />

                    {informativeMeasurements.length > 0 && (
                        <Button variant="secondary" onClick={() => setExportMeasurementsOpen(!exportMeasurementsOpen)}><Trans>exportMeasurements</Trans></Button>
                    )}
                </div>

                <div className="d-flex justify-content-end">
                    <Collapse in={exportMeasurementsOpen} className="mt-3">
                        <div id="exportMeasurements">
                            <ExportMeasurementsForm clientMeasurementApi={clientMeasurementApi} />
                        </div>
                    </Collapse>
                </div>
            </>
        )
    )
}

export default ClientMeasurements;
