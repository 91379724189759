import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { Form, InputGroup } from "react-bootstrap";

function FilterStringInput({ filterStr, setFilterStr, onEditing }) {
    return (
        <InputGroup className="text-sm">
            <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            <Form.Control
                type="text"
                placeholder={t("filterStringSearch")}
                value={filterStr}
                onChange={(e) => setFilterStr(e.target.value)}
                onFocus={() => {
                    if (onEditing) {
                        onEditing(true);
                    }
                }}
            />
        </InputGroup>
    );
}

export default FilterStringInput;
