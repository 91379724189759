import { useMemo } from "react";
import useLocalStorageState from "use-local-storage-state";
import ClientProfessionApi from "./clientProfessionApi";

function useClientProfessionApi() {
    const [sessionId, ] = useLocalStorageState("sessionId", null);
    return useMemo(() => new ClientProfessionApi(sessionId), [sessionId]);
}

export default useClientProfessionApi;
