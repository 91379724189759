import { Button } from "react-bootstrap";
import useEmployeeApi from "../../app/useEmployeeApi";
import { useCallback, useEffect, useState } from "react";
import { showNotification } from "../../utils/notification";
import { t } from "i18next";
import { downloadBlob, selectFile } from "../../utils/blob";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faFileExcel, faPlus, faRefresh, faUpload } from "@fortawesome/free-solid-svg-icons";
import EmployeeTable from "./EmployeeTable";
import EmployeeEdit from "./EmployeeEdit";
import Loader from "../global/Loader";
import useProfessionApi from "../../app/useProfessionApi";
import useDebounce from "../../utils/useDebounce";
import FilterStringInput from "../global/FilterStringInput";

function EmployeeTab() {
    const employeeApi = useEmployeeApi();
    const professionApi = useProfessionApi();

    const [employees, setEmployees] = useState(null);
    const [reloadRequest, setReloadRequest] = useState(false);

    const [filterStr, setFilterStr, debouncedFilterStr] = useDebounce("", 500);
    const [loading, setLoading] = useState(false);

    const [riskFactorNames, setRiskFactorNames] = useState(null);
    const [climaticNames, setClimaticNames] = useState(null);

    const [addFormOpen, setAddFormOpen] = useState(false);

    const onSave = useCallback((employee) => {
        if (employee.id) {
            employeeApi.updateEmployee(employee)
                .then((data) => {
                    // Replace employee in list
                    const newEmployees = employees.map((e) => {
                        if (e.id === employee.id) {
                            return data;
                        }
                        return e;
                    });
                    setEmployees(newEmployees);
                    showNotification(t("employeeTableUpdateSuccess"), 'success');
                })
                .catch((error) => {
                    console.error(error);
                    showNotification(t("employeeTableUpdateError") + ": " + error.message, 'danger');
                });
        } else {
            employeeApi.addEmployee(employee)
                .then((data) => {
                    // Add new employee to list
                    setEmployees([...employees, data]);
                    showNotification(t("employeeTableCreateSuccess"), 'success');
                })
                .catch((error) => {
                    console.error(error);
                    showNotification(t("employeeTableCreateError") + ": " + error.message, 'danger');
                });
        }
    }, [employeeApi, employees]);

    const onDelete = useCallback((employeeId) => {
        employeeApi.deleteEmployee(employeeId)
            .then(() => {
                // Remove employee from list
                const newEmployees = employees.filter((e) => e.id !== employeeId);
                setEmployees(newEmployees);
                showNotification(t("employeeTableDeleteSuccess"), 'success');
            })
            .catch((error) => {
                console.error(error);
                showNotification(t("employeeTableDeleteError") + ": " + error.message, 'danger');
            });
    }, [employeeApi, employees]);

    const exportXlsx = useCallback((genExampleData) => {
        employeeApi.exportEmployees(genExampleData)
            .then((data) => {
                // FIXME: use client company name
                const filename = genExampleData ? 'Список сотрудников - пример.xlsx' : 'Список сотрудников.xlsx';
                downloadBlob(data, filename);
            })
            .catch((error) => {
                console.error(error);
                showNotification(t("employeeTableExportError") + ": " + error.message, 'danger');
            });
    }, [employeeApi]);

    const importXlsx = useCallback(() => {
        // Show file dialog
        selectFile('.xlsx')
            .then((file) => {
                employeeApi.importEmployees(file, true)
                    .then((data) => {
                        showNotification(t("employeeTableImportSuccess"), 'success');
                        setReloadRequest(!reloadRequest);
                    })
                    .catch((error) => {
                        console.error(error);
                        showNotification(t("employeeTableImportError") + ": " + error.message, 'danger');
                    });
            })
            .catch((error) => {
                console.error(error);
                showNotification(t("employeeTableImportError") + ": " + error.message, 'danger');
            });
    }, [employeeApi, reloadRequest]);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);

            try {
                const data = await employeeApi.getEmployees(debouncedFilterStr);
                setEmployees(data);
            } catch (error) {
                console.error(error);
                showNotification(t("employeeTableFetchError") + ": " + error.message, 'danger');
            }

            setLoading(false);
        }

        const delayedFetch = setTimeout(fetchData, 200);
        return () => clearTimeout(delayedFetch);
    }, [debouncedFilterStr, employeeApi, reloadRequest]);

    useEffect(() => {
        async function fetchData() {
            try {
                const rfn = await professionApi.getRiskFactorList(true);
                setRiskFactorNames(rfn);

                const cn = await professionApi.getClimaticList(true);
                setClimaticNames(cn);
            } catch (error) {
                console.error(error);
                showNotification(t("employeeTableFetchError") + ": " + error.message, 'danger');
            }
        }

        const delayedFetch = setTimeout(fetchData, 200);
        return () => clearTimeout(delayedFetch);
    }, []);

    return (
        employees === null ? (
            <Loader />
        ) : (
            <div className="mt-4">
                {/* Buttons toolbar */}
                <div className="mt-3 d-flex justify-content-between gap-2">
                    <Button
                        variant="primary"
                        onClick={() => setReloadRequest((prev) => !prev)}>
                        <FontAwesomeIcon icon={faRefresh} /> <Trans>employeeTableReload</Trans>
                    </Button>

                    <div className="flex-grow-1">
                        <FilterStringInput filterStr={filterStr} setFilterStr={setFilterStr} />
                    </div>

                    <Button
                        variant="link"
                        onClick={() => exportXlsx(true)
                        }>
                        <FontAwesomeIcon icon={faFileExcel} /> <Trans>employeeTableXlsxExample</Trans>
                    </Button>

                    <Button
                        variant="secondary"
                        className="ms-2"
                        size="sm"
                        onClick={importXlsx}>
                        <FontAwesomeIcon icon={faUpload} /> <Trans>employeeTableXlsxImport</Trans>
                    </Button>

                    <Button
                        variant="secondary"
                        className="ms-2"
                        size="sm"
                        onClick={() => exportXlsx(false)}>
                        <FontAwesomeIcon icon={faDownload} /> <Trans>employeeTableXlsxExport</Trans>
                    </Button>
                </div>

                {/* Main employee table */}
                {!loading ? (
                    <EmployeeTable
                        employees={employees}
                        riskFactorNames={riskFactorNames}
                        climaticNames={climaticNames}
                        onSave={onSave}
                        onDelete={onDelete} />
                ) : (
                    <Loader />
                )}

                {/* Add form */}
                <div className="d-flex justify-content-center mt-3">
                    <Button
                        variant="primary"
                        onClick={() => setAddFormOpen(true)}>
                        <FontAwesomeIcon icon={faPlus} /> <Trans>employeeTableAdd</Trans>
                    </Button>
                </div>

                {addFormOpen && (
                    <EmployeeEdit
                        employee={{}}
                        riskFactorNames={riskFactorNames}
                        climaticNames={climaticNames}
                        onSave={onSave}
                        onCancel={() => setAddFormOpen(false)}
                    />
                )}
            </div>
        )
    );
}

export default EmployeeTab;
