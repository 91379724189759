import { useEffect } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { t } from "i18next";
import ProfileData from "../components/profile/ProfileData";
import Footer from "../components/global/Footer";
import Header from "../components/global/Header";
import { Helmet } from "react-helmet-async";
import useLocalStorageState from "use-local-storage-state";
import CartHistory from "../components/cart/CartHistory";
import { useLocation, useNavigate } from "react-router-dom";

function Profile() {
    const [sessionId,] = useLocalStorageState('sessionId', { defaultValue: null });

    const location = useLocation();
    const navigate = useNavigate();

    // Extract the active tab from the URL query parameters
    const queryParams = new URLSearchParams(location.search);
    const activeTab = queryParams.get('tab') || 'profileData';

    const handleTabSelect = (key) => {
        // Update the URL when a tab is selected
        navigate(`?tab=${key}`);
    };

    useEffect(() => {
        if (!sessionId) {
            window.location.href = '/login';
        }
    }, [sessionId]);

    return (
        <>
            <Helmet>
                <title>{t("profilePageTitle")} - FeetApp</title>
            </Helmet>

            <Header />

            <Container>
                <Tabs defaultActiveKey="profileData" activeKey={activeTab} onSelect={handleTabSelect} mountOnEnter className="mt-3">
                    <Tab eventKey="profileData" title={t("profileDataTab")}>
                        <ProfileData />
                    </Tab>

                    <Tab eventKey="cartHistory" title={t("cartHistoryTab")}>
                        <CartHistory />
                    </Tab>
                </Tabs>
            </Container>

            <Footer />
        </>
    );
}

export default Profile;
