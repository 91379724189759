import { Container } from 'react-bootstrap';
import { t } from 'i18next';
import '../../styles/Footer.scss';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            <div className="footer-divider mt-auto"></div>
            <footer className="footer bg-light mt-3 py-2">
                <Container className='text-center'>
                    <p className='text-muted'>
                        &copy; {new Date().getFullYear()} {t('EmitechCompany')} <a href="mailto:info@emitech.tech">info@emitech.tech</a> <br />
                        
                        <Link to="/userAgreement" className='text-muted'>{t('footerUserAgreement')}</Link>
                        <Link to="/privacyPolicy" className='text-muted ms-1'>{t('footerPrivacyPolicy')}</Link>
                        <Link to="/accountDeletionInstructions" className='text-muted ms-1'>{t('footerAccountDeletionInstructions')}</Link>
                    </p>
                </Container>
            </footer>
        </>
    );
};

export default Footer;