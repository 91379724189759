import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row } from "react-bootstrap";
import { Trans } from "react-i18next";

function ProfessionDetails({ profession, onEdit, onDelete }) {
    return (
        <>
            <Row>
                <Col className="text-muted"><Trans>professionDetailsName</Trans></Col>
                <Col>{profession.name}</Col>
            </Row>

            <Row>
                <Col className="text-muted"><Trans>professionDetailsIndustry</Trans></Col>
                <Col>{profession.industry}</Col>
            </Row>

            <Row>
                <Col className="text-muted"><Trans>professionDetailsNorm</Trans></Col>
                <Col>{profession.norm}</Col>
            </Row>

            {onEdit && onDelete && (
                <Row>
                    <Col>
                        <div className="d-flex justify-content-center">
                            <Button variant="link" onClick={onEdit}><FontAwesomeIcon icon={faEdit} /> <Trans>Edit</Trans></Button>
                            <Button variant="link" className="text-danger" onClick={() => onDelete(profession.id)}><FontAwesomeIcon icon={faTrash} /> <Trans>Delete</Trans></Button>
                        </div>
                    </Col>
                </Row>
            )}
        </>
    );
}

export default ProfessionDetails;
