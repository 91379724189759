import { faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Collapse, Container, Form, InputGroup, Overlay, Popover, Row } from "react-bootstrap";
import { Trans } from "react-i18next";
import { showNotification } from "../../utils/notification";
import ProtPropertySelect from "./ProtPropertySelect";
import Loader from "../global/Loader";
import useProfessionApi from "../../app/useProfessionApi";
import { buildProtPropertiesStr, parseProtPropertiesStr } from "../../utils/protPropertiesStr";
import useClientProfessionApi from "../../app/useClientProfessionApi";
import { buildRiskFactorsStr, parseRiskFactorsStr } from "../../utils/riskFactors";
import RiskFactorsSelect from "./RiskFactorsSelect";

function NormEdit({ norm, onSave, onDelete, onCancel }) {
    const professionApi = useProfessionApi();
    const clientProfessionApi = useClientProfessionApi();

    const [name, setName] = useState(norm?.name || "");
    const [protPropertiesStr, setProtPropertiesStr] = useState(norm?.prot_properties_str || "");
    const [riskFactorsStr, setRiskFactorsStr] = useState(norm?.risk_factors_str || "");

    const [allProtProperties, setAllProtProperties] = useState(null);
    const [allRiskFactors, setAllRiskFactors] = useState(null);

    const allRiskFactorNames = useMemo(() => allRiskFactors?.map((rf) => rf.name), [allRiskFactors]);

    const [showProtPropertiesAdd, setShowProtPropertiesAdd] = useState(false);
    const protPropertiesAddTarget = useRef(null);

    const [showProtPropertiesVariant, setShowProtPropertiesVariant] = useState(false);
    const protPropertiesVariantTarget = useRef(null);

    const [showRiskFactorsAdd, setShowRiskFactorsAdd] = useState(false);
    const riskFactorsAddTarget = useRef(null);

    const reset = useCallback(() => {
        setName(norm.name);
        setProtPropertiesStr(norm.prot_properties_str);
        setRiskFactorsStr(norm.risk_factors_str);
    }, [norm]);

    const clear = useCallback(() => {
        setName("");
        setProtPropertiesStr("");
        setRiskFactorsStr("");
    }, []);

    const onCancelClick = useCallback(() => {
        if (norm.id) {
            reset();
        } else {
            clear();
        }

        onCancel();
    }, [norm, onCancel, reset, clear]);

    const onSaveClick = useCallback(() => {
        onSave({
            id: norm.id,
            name,
            prot_properties_str: protPropertiesStr,
            risk_factors_str: riskFactorsStr
        });

        onCancel();
    }, [onSave, norm.id, name, protPropertiesStr, riskFactorsStr, onCancel]);

    const selectedProtProperties = useMemo(() => {
        const props = parseProtPropertiesStr(protPropertiesStr);
        const flattenProps = props.flat();
        const uniqueProps = [...new Set(flattenProps)];
        return uniqueProps;
    }, [protPropertiesStr]);

    const selectedRiskFactorNames = useMemo(() => {
        const riskFactorCodeNames = parseRiskFactorsStr(riskFactorsStr);
        const riskFactorNames = riskFactorCodeNames.map((codeName) => {
            const riskFactor = allRiskFactors?.find((rf) => rf.code_name === codeName);
            return riskFactor?.name;
        });
        return riskFactorNames;
    }, [riskFactorsStr, allRiskFactors]);

    const handleProtPropertyAdd = useCallback((codeName) => {
        const props = parseProtPropertiesStr(protPropertiesStr);
        if (props.flat().includes(codeName)) {
            // Remove from list
            const filteredProps = props.map((item) => item.filter((prop) => prop !== codeName));
            setProtPropertiesStr(buildProtPropertiesStr(filteredProps));
        } else {
            // Add to list
            const newProps = [...props, [codeName]];
            setProtPropertiesStr(buildProtPropertiesStr(newProps));
        }
    }, [protPropertiesStr, setProtPropertiesStr]);

    const handleProtPropertyVariant = useCallback((codeName) => {
        const props = parseProtPropertiesStr(protPropertiesStr);
        if (props.flat().includes(codeName)) {
            // Remove from list
            const filteredProps = props.map((item) => item.filter((prop) => prop !== codeName));
            setProtPropertiesStr(buildProtPropertiesStr(filteredProps));
        } else {
            // Add to list as an option to the last item
            if (props.length > 0) {
                props[props.length - 1].push(codeName);
                setProtPropertiesStr(buildProtPropertiesStr(props));
            }
        }
    }, [protPropertiesStr, setProtPropertiesStr]);

    const handleRiskFactorsAdd = useCallback((name) => {
        const codeName = allRiskFactors.find((rf) => rf.name === name)?.code_name;
        if (!codeName) {
            return;
        }

        const riskFactors = parseRiskFactorsStr(riskFactorsStr);
        if (riskFactors.includes(codeName)) {
            // Remove from list
            const filteredRiskFactors = riskFactors.filter((item) => item !== codeName);
            setRiskFactorsStr(buildRiskFactorsStr(filteredRiskFactors));
        } else {
            // Add to list
            const newRiskFactors = [...riskFactors, codeName];
            setRiskFactorsStr(buildRiskFactorsStr(newRiskFactors));
        }
    }, [allRiskFactors, riskFactorsStr]);

    useEffect(() => {
        async function fetchAll() {
            try {
                const protProperties = await professionApi.getProtectivePropertyList();
                setAllProtProperties(protProperties);
            } catch (e) {
                console.error(e);
                showNotification(t("errorLoadingProtectiveProperties"), "danger");
            }

            try {
                const riskFactors = await clientProfessionApi.getRiskFactors(false);
                setAllRiskFactors(riskFactors);
            } catch (e) {
                console.error(e);
                showNotification(t("errorLoadingRiskFactors"), "danger");
            }
        }

        const delayedFetch = setTimeout(fetchAll, 200);

        return () => clearTimeout(delayedFetch);
    }, [clientProfessionApi, professionApi]);

    return (
        <Container>
            <Row>
                <Col xs={12} lg={6} className="mx-auto">
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label><Trans>normName</Trans></Form.Label>
                            <Form.Control
                                type="text"
                                value={name}
                                onChange={e => setName(e.target.value)}
                                placeholder={t("normNamePlaceholder")}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label><Trans>normProtPropertiesStr</Trans></Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    value={protPropertiesStr}
                                    onChange={e => setProtPropertiesStr(e.target.value)}
                                    placeholder={t("normProtPropertiesStrPlaceholder")}
                                />

                                {allProtProperties === null ? (
                                    <Loader compact={true} />
                                ) : (
                                    <>
                                        <Button
                                            variant="outline-secondary"
                                            ref={protPropertiesAddTarget}
                                            onClick={() => setShowProtPropertiesAdd(true)}
                                        >
                                            + <Trans>normProtPropertyAdd</Trans>
                                        </Button>
                                        <Button
                                            variant="outline-secondary"
                                            ref={protPropertiesVariantTarget}
                                            onClick={() => setShowProtPropertiesVariant(true)}
                                        >
                                            / <Trans>normProtPropertyVariant</Trans>
                                        </Button>
                                    </>
                                )}
                            </InputGroup>
                            <Form.Text className="text-muted"><Trans>normProtPropertiesStrHelp</Trans></Form.Text>

                            {allProtProperties !== null && (
                                <>
                                    <Overlay
                                        show={showProtPropertiesAdd}
                                        target={protPropertiesAddTarget.current}
                                        placement="bottom"
                                        rootClose
                                        onHide={() => setShowProtPropertiesAdd(false)}
                                    >
                                        <Popover>
                                            <div className="p-2">
                                                <ProtPropertySelect
                                                    protProperties={allProtProperties}
                                                    values={selectedProtProperties}
                                                    handleSelected={handleProtPropertyAdd}
                                                />
                                            </div>
                                        </Popover>
                                    </Overlay>

                                    <Overlay
                                        show={showProtPropertiesVariant}
                                        target={protPropertiesVariantTarget.current}
                                        placement="bottom"
                                        rootClose
                                        onHide={() => setShowProtPropertiesVariant(false)}
                                    >
                                        <Popover>
                                            <div className="p-2">
                                                <ProtPropertySelect
                                                    protProperties={allProtProperties}
                                                    values={selectedProtProperties}
                                                    handleSelected={handleProtPropertyVariant}
                                                />
                                            </div>
                                        </Popover>
                                    </Overlay>
                                </>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label><Trans>normRiskFactorsStr</Trans></Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    value={riskFactorsStr}
                                    onChange={e => setRiskFactorsStr(e.target.value)}
                                    placeholder={t("normRiskFactorsStrPlaceholder")}
                                />

                                {allRiskFactors === null ? (
                                    <Loader compact={true} />
                                ) : (
                                    <Button
                                        variant="outline-secondary"
                                        ref={riskFactorsAddTarget}
                                        onClick={() => setShowRiskFactorsAdd(true)}
                                    >
                                        + <Trans>normRiskFactorAdd</Trans>
                                    </Button>
                                )}
                            </InputGroup>
                            <Form.Text className="text-muted"><Trans>normRiskFactorsStrHelp</Trans></Form.Text>

                            {allRiskFactors !== null && (
                                <Collapse in={showRiskFactorsAdd}>
                                    <div id="risk-factors-add">
                                        <RiskFactorsSelect
                                            riskFactors={allRiskFactorNames}
                                            active={true}
                                            onEditing={() => { }}
                                            selectedRiskFactors={selectedRiskFactorNames}
                                            setSelectedRiskFactors={() => { }}
                                            handleSelected={handleRiskFactorsAdd}
                                        />
                                    </div>
                                </Collapse>
                            )}
                        </Form.Group>

                        <div className="d-flex justify-content-center">
                            {norm.id !== undefined && (
                                <Button
                                    variant="danger"
                                    className="me-auto"
                                    onClick={() => onDelete(norm.id)}
                                >
                                    <FontAwesomeIcon icon={faTrash} /> <Trans>Delete</Trans>
                                </Button>
                            )}

                            <Button
                                variant="secondary"
                                className="ms-auto me-2"
                                onClick={onCancelClick}
                            >
                                <Trans>Cancel</Trans>
                            </Button>

                            <Button
                                variant="primary"
                                className="me-auto"
                                onClick={onSaveClick}
                            >
                                <FontAwesomeIcon icon={faSave} /> <Trans>Save</Trans>
                            </Button>
                        </div>

                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default NormEdit;
