import { t } from "i18next";
import { Helmet } from "react-helmet-async";
import Header from "../components/global/Header";
import { Container } from "react-bootstrap";
import EmployeeTab from "../components/employee/EmployeeTab";
import Footer from "../components/global/Footer";

const EmployeeView = () => {
    return (
        <>
            <Helmet>
                <title>{t("employeeViewPageTitle")}</title>
            </Helmet>

            <Header />

            <Container>
                <EmployeeTab />
            </Container>

            <Footer />
        </>
    )
};

export default EmployeeView;
