import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row } from "react-bootstrap";
import { Trans } from "react-i18next";

function NormDetails({ norm, onEdit, onDelete }) {
    return (
        <>
            <Row>
                <Col className="text-muted"><Trans>normDetailsName</Trans></Col>
                <Col>{norm.name}</Col>
            </Row>

            <Row>
                <Col className="text-muted"><Trans>normDetailsProtProperties</Trans></Col>
                <Col>{norm.prot_properties_str}</Col>
            </Row>

            <Row>
                <Col className="text-muted"><Trans>normDetailsRiskFactors</Trans></Col>
                <Col>{norm.risk_factors_str}</Col>
            </Row>

            {onEdit && onDelete && (
                <Row>
                    <Col>
                        <div className="d-flex justify-content-center">
                            <Button variant="link" onClick={onEdit}><FontAwesomeIcon icon={faEdit} /> <Trans>Edit</Trans></Button>
                            <Button variant="link" className="text-danger" onClick={() => onDelete(norm.id)}><FontAwesomeIcon icon={faTrash} /> <Trans>Delete</Trans></Button>
                        </div>
                    </Col>
                </Row>
            )}
        </>
    );
}

export default NormDetails;
